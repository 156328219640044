import { Chooser } from "./Chooser";



function App() {
  return (
    <Chooser />
  )
}

export default App;
